import helper from '@/libs/helper'
const url = '/tagihan/'
import GeneralApi from "@/composables/GeneralApi";

class Tagihan extends GeneralApi {
  constructor(g) {
    super(g, url);
    this.url = url;
  }

  postTagihan(data) {
    return this.g.$http({
      url: this.url,
      headers: { Authorization: `Bearer ${this.g.$cookies.get('token')}` },
      method: 'post',
      data,
    })
      .then(() => {
        this.g.$router.push({ name: "tagihan" });
      })
      .catch(err => {
        helper.showError(this.g, err, `Error Get Data`)

        return { isSuccess: false }
      })
  }

  putTagihan(id, data) {
    return this.g.$http({
      url: `${this.url}${id}`,
      headers: { Authorization: `Bearer ${this.g.$cookies.get('token')}` },
      method: 'put',
      data,
    })
      .then(() => {
        this.g.$router.push({ name: "tagihan" });
      })
      .catch(err => {
        helper.showError(this.g, err, `Error Ubah Data`)

        return { isSuccess: false }
      })
  }
}

export default Tagihan