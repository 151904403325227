<template>
  <div>
    <b-card
      no-body
      v-if="action == 'Tambah' || action == 'Ubah'"
    >
      <b-card-header>
        <b-card-title>{{ action }} Data</b-card-title>
        <b-card-title class="text-primary">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="btn-icon"
            size="sm"
            :to="{ name: 'tagihan' }"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-form
          class="needs-validation"
          @submit.prevent="submit(false)"
        >
          <b-form-row>
            <b-col
              md="10"
              offset-md="1"
            >
              <!-- input kelompok -->
              <b-form-group
                label="Kelompok"
                label-for="kelompok"
              >
                <b-form-select
                  v-model="v$.kelompok.$model"
                  :options="dataKelompok"
                  :state="validateState(v$.kelompok)"
                  @change="changeKelompok"
                />
                <b-form-invalid-feedback
                  v-for="(error, index) of v$.kelompok.$errors"
                  :key="index"
                >
                  Kelompok tidak boleh kosong
                </b-form-invalid-feedback>
              </b-form-group>

              <!-- input pengguna -->
              <b-form-group
                :label="labelPengguna"
                label-for="pengguna"
                v-if="
                  kelompok == 'RS / Klinik' ||
                  kelompok == 'Dokter Pemeriksa' ||
                  kelompok == 'Dokter Pengirim'
                "
              >
                <v-select
                  v-model="v$.pengguna.$model"
                  :options="dataPengguna"
                  label="text"
                />
                <small
                  class="text-danger"
                  v-if="
                    kelompok == 'RS / Klinik' && v$.pengguna.$errors.length > 0
                  "
                >
                  RS / Klinik tidak boleh kosong
                </small>
                <small
                  class="text-danger"
                  v-if="
                    kelompok == 'Dokter Pemeriksa' &&
                    v$.pengguna.$errors.length > 0
                  "
                >
                  Dokter Pemeriksa tidak boleh kosong
                </small>
                <small
                  class="text-danger"
                  v-if="
                    kelompok == 'Dokter Pengirim' &&
                    v$.pengguna.$errors.length > 0
                  "
                >
                  Dokter Pengirim tidak boleh kosong
                </small>
              </b-form-group>

              <!-- input noSediaan -->
              <b-form-group
                label="No Sediaan"
                label-for="noSediaan"
                v-else-if="kelompok == 'Langsung'"
              >
                <v-select
                  id="noSediaan"
                  v-model="noSediaan"
                  label="text"
                  :options="dataNoSediaan"
                  @search="searchNoSediaan"
                />
                <small
                  class="text-danger"
                  v-if="v$.noSediaan.$errors.length > 0"
                >
                  No Sediaan tidak boleh kosong
                </small>
              </b-form-group>

              <b-form-group
                label="Tanggal"
                label-for="tanggal"
                v-if="kelompok != 'Langsung'"
              >
                <flat-pickr
                  v-model="tanggal"
                  class="form-control"
                  :config="{ mode: 'range', dateFormat: 'd-m-Y' }"
                />
              </b-form-group>

              <b-col
                cols="12"
                class="text-right pr-0"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                >
                  <div v-if="isCari">
                    <b-spinner small />
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div v-else>Cari</div>
                </b-button>
              </b-col>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-body>
    </b-card>

    <!-- <b-card 
            v-if="dataListTagihan.length > 0"
            title="Detail Tagihan"
        > -->
    <b-card
      no-body
      v-if="dataListTagihan.length > 0"
    >
      <b-card-header>
        <b-card-title>Detail Tagihan</b-card-title>
        <b-card-title
          class="text-primary"
          v-if="action != 'Tambah'"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="btn-icon"
            size="sm"
            :to="{ name: 'tagihan' }"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-form
          class="needs-validation"
          @submit.prevent="submit(true)"
        >
          <vue-good-table
            :columns="columns"
            :rows="dataListTagihan"
            :totalRows="10"
            :isLoading.sync="isLoading"
            mode="remote"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            @on-selected-rows-change="selectionChangedTable"
            :sort-options="{
              enabled: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: biaya -->
              <span
                v-if="props.column.field === 'biaya'"
                class="align-self-center"
              >
                <!-- {{props.row}} -->
                <b-form-input
                  @keyup="changeBiaya(props.row.originalIndex, props.row.biaya)"
                  v-model="props.row.biaya"
                  @keydown="changeIntOnly"
                />
              </span>

              <!-- Column: noSediaan -->
              <span
                v-else-if="props.column.field === 'noSediaan'"
                class="align-self-center"
              >
                <router-link :to="{
                    name: 'pemeriksaanPemeriksaanUbah',
                    params: { id: props.row.id },
                  }">{{ props.formattedRow[props.column.field] }}</router-link>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>

          <b-card-body class="pl-0 pt-2 pr-0 pb-0">
            <b-row>
              <b-col
                md="4"
                offset-md="8"
              >
                <b-row>
                  <label class="col-8"> Subtotal </label>
                  <label class="col-4">{{ subtotal }}</label>
                </b-row>
                <!-- input potongan biaya -->
                <b-form-group
                  label="Tambahan Biaya"
                  label-for="tambahanBiaya"
                >
                  <b-row>
                    <b-col md="8">
                      <b-form-input
                        id="tambahanBiaya"
                        placeholder="Biaya"
                        v-model="tambahanBiaya"
                        @keyup="keyupTambahanBiaya"
                        @keydown="changeIntOnly"
                        @input="changeTambahanBiaya"
                      />
                    </b-col>
                    <b-col md="4">
                      <b-form-input
                        id="tambahanPersen"
                        placeholder="%"
                        v-model="tambahanPersen"
                        @keyup="keyupTambahanPersen"
                        @keydown="changeIntOnly"
                        @input="changeTambahanPersen"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>

                <!-- input potongan biaya -->
                <b-form-group
                  label="Potongan Harga"
                  label-for="potonganHarga"
                >
                  <b-row>
                    <b-col md="8">
                      <b-form-input
                        id="potonganHarga"
                        placeholder="Potongan"
                        v-model="potonganHarga"
                        @keyup="keyupPotonganHarga"
                        @keydown="changeIntOnly"
                        @input="changePotonganHarga"
                      />
                    </b-col>
                    <b-col md="4">
                      <b-form-input
                        id="potonganPersen"
                        placeholder="%"
                        v-model="potonganPersen"
                        @keyup="keyupPotonganPersen"
                        @keydown="changeIntOnly"
                        @input="changePotonganPersen"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>

                <!-- input pajak -->
                <b-form-group
                  label="Pajak"
                  label-for="pajak"
                >
                  <b-row>
                    <b-col md="8">
                      <b-form-input
                        id="pajak"
                        placeholder="Pajak"
                        v-model="pajak"
                        @keyup="keyupPajak"
                        @keydown="changeIntOnly"
                        @input="changePajak"
                      />
                    </b-col>
                    <b-col md="4">
                      <b-form-input
                        id="pajakPersen"
                        placeholder="%"
                        v-model="pajakPersen"
                        @keyup="keyupPajakPersen"
                        @keydown="changeIntOnly"
                        @input="changePajakPersen"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-row>
                  <label class="col-8"> Total </label>
                  <label class="col-4">{{ total }}</label>
                </b-row>

                <!-- input bank -->
                <b-form-group
                  label="Bank"
                  label-for="bank"
                  class="mt-1"
                >
                  <b-form-select
                    id="Bank"
                    v-model="v$.bank.$model"
                    :options="dataBank"
                    :state="validateState(v$.bank)"
                  />
                  <b-form-invalid-feedback
                    v-for="(error, index) of v$.bank.$errors"
                    :key="index"
                  >
                    {{ error.$message }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!-- input adminKeuangan -->
                <b-form-group
                  label="Admin Keuangan"
                  label-for="adminKeuangan"
                  class="mt-1"
                >
                  <b-form-select
                    id="adminKeuangan"
                    v-model="adminKeuangan"
                    :options="dataAdminKeuangan"
                  />
                </b-form-group>

                <b-col
                  cols="12"
                  class="text-right pr-0 pt-2"
                  v-if="statusKonfirmasiVal != 1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                  >
                    <div v-if="isSubmit">
                      <b-spinner small />
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else>
                      {{ action == "Konfirmasi" ? "Konfirmasi" : "Simpan" }}
                    </div>
                  </b-button>
                </b-col>
              </b-col>
            </b-row>
          </b-card-body>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BFormRow,
  BCol,
  BFormInput,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BCardHeader,
  BCardBody,
  BButton,
  BFormGroup,
  BCardTitle,
  BSpinner,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { ref, computed, watchEffect } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import {
  convertPersenToInt,
  convertIntToPersen,
  intThousand,
  setInputIntOnly,
} from "@/libs/helper";
import ApiBank from "@/composables/mst/tagihan/bank";
import ApiTagihan from "@/composables/tagihan";

export default {
  props: {
    dataListTagihanVal: Array,
    tambahanBiayaVal: String,
    potonganHargaVal: String,
    pajakVal: String,
    tambahanPersenVal: String,
    potonganPersenVal: String,
    pajakPersenVal: String,
    statusKonfirmasiVal: String,
    bankVal: String,
    adminKeuanganVal: String,
    penggunaVal: String,
    kelompokVal: String,

    dataBankVal: Array,
    dataAdminKeuanganVal: Array,
    action: String,
    id: String,
  },
  setup(props, context) {
    const g = context.root;

    let isCari = ref(false);
    let isSubmit = ref(false);
    const $http = g.$http;

    const apiBank = new ApiBank(g);
    const apiTagihan = new ApiTagihan(g);

    //form name
    let kelompok = ref(null);
    let kelompok2 = ref(null);
    let pengguna = ref({ text: "-- Pilih Opsi --", value: null });
    let pengguna2 = ref("");
    let tanggal = ref("");
    let noSediaan = ref({ text: "-- Pilih Opsi --", value: null });

    let labelPengguna = ref("");

    let tambahanBiaya = ref("");
    let tambahanPersen = ref("");
    let potonganHarga = ref("");
    let potonganPersen = ref("");
    let pajak = ref("");
    let pajakPersen = ref("");
    let bank = ref(null);
    let adminKeuangan = ref(null);

    let dataListTagihan = ref([]);
    let dataPengguna = ref([]);
    let dataKelompok = ref([
      { text: "-- Pilih Opsi --", value: null },
      { text: "RS / Klinik", value: "RS / Klinik" },
      { text: "Dokter Pemeriksa", value: "Dokter Pemeriksa" },
      { text: "Dokter Pengirim", value: "Dokter Pengirim" },
      { text: "Langsung", value: "Langsung" },
    ]);
    let dataSelectedRows = ref([]);
    let dataNoSediaan = ref([{ text: "-- Pilih Opsi --", value: null }]);
    let dataBank = ref([]);
    let dataAdminKeuangan = ref([]);

    let isLoading = ref(false);
    let isValidasiSubmit = ref(false);

    watchEffect(() => {
      tambahanBiaya.value = props.tambahanBiayaVal;
      potonganHarga.value = props.potonganHargaVal;
      pajak.value = props.pajakVal;
      tambahanPersen.value = props.tambahanPersenVal;
      potonganPersen.value = props.potonganPersenVal;
      pajakPersen.value = props.pajakPersenVal;
      bank.value = props.bankVal;
      adminKeuangan.value = props.adminKeuanganVal;
      kelompok2.value = props.kelompokVal;
      pengguna2.value = props.penggunaVal;

      dataListTagihan.value = props.dataListTagihanVal;
      dataBank.value = props.dataBankVal;
      dataAdminKeuangan.value = props.dataAdminKeuanganVal;
    });

    const requiredPengguna = (val) => {
      if (
        (typeof val == "undefined" || val == "" || val == null) &&
        (kelompok.value == "RS / Klinik" ||
          kelompok.value == "Dokter Pemeriksa") &&
        props.action == "Tambah"
      )
        return false;

      return true;
    };

    const checkSelect = (val) => {
      if (
        !val.value &&
        kelompok.value == "Langsung" &&
        props.action == "Tambah"
      )
        return false;
      return true;
    };

    const requiredKelompok = (val) => {
      if (
        (typeof val == "undefined" || val == "" || val == null) &&
        props.action == "Tambah"
      )
        return false;
      return true;
    };

    // validation
    const requiredMsg = "tidak boleh kosong";
    const $externalResults = ref({});
    const rules = computed(() => ({
      kelompok: {
        requiredKelompok,
      },
      pengguna: {
        requiredPengguna,
      },
      noSediaan: { checkSelect },
      bank: isValidasiSubmit.value
        ? {
            required: helpers.withMessage("Bank " + requiredMsg, required),
          }
        : {},
    }));

    const v$ = useVuelidate(
      rules,
      { kelompok, pengguna, noSediaan, bank },
      { $externalResults, $autoDirty: true }
    );

    const submitForm = async () => {
      isSubmit.value = true;
      const isFormCorrect = await v$.value.$validate();
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      //jika error validasi
      if (!isFormCorrect) {
        isSubmit.value = false;
        return;
      }

      // mapping data
      let data = {
        dataTagihan: dataSelectedRows.value,
        tambahanBiaya:
          tambahanBiaya.value != "" &&
          typeof tambahanBiaya.value !== "undefined" &&
          tambahanBiaya.value != null
            ? tambahanBiaya.value.toString().replace(/\./g, "")
            : null,
        potonganHarga:
          potonganHarga.value != "" &&
          typeof potonganHarga.value !== "undefined" &&
          potonganHarga.value != null
            ? potonganHarga.value.toString().replace(/\./g, "")
            : null,
        pajak:
          pajak.value != "" &&
          typeof pajak.value !== "undefined" &&
          pajak.value != null
            ? pajak.value.toString().replace(/\./g, "")
            : null,
        kelompok: kelompok2.value,
        pengguna: pengguna2.value,
        bank: bank.value,
        adminKeuangan: adminKeuangan.value,
        id: props.id,
      };

      if (props.action == "Tambah") await apiTagihan.postTagihan(data);
      else {
        data.isKonfirmasi = props.action != "Ubah";
        await apiTagihan.putTagihan(props.id, data);
      }
    };

    const getBank = async (id = "") => {
      const response = await apiBank.getOpsiBank(id);
      const { banks, idBank } = response.data.data;
      dataBank.value = [{ text: "-- Pilih Opsi --", value: null }, ...banks];
      bank.value = idBank || null;
    };

    const submitFormCari = async () => {
      isCari.value = true;
      const isFormCorrect = await v$.value.$validate();
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      //jika error validasi
      if (!isFormCorrect) {
        isCari.value = false;
        return;
      }

      $http({
        url: `/tagihan/get-list-tagihan/${props.id}`,
        method: "post",
        data: {
          kelompok: kelompok.value,
          pengguna: pengguna.value.value,
          tanggal: tanggal.value,
          pesanan: noSediaan.value,
        },
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          isCari.value = false;
          const { tagihans, idPengguna } = res.data.data;
          dataListTagihan.value = tagihans;
          kelompok2.value = kelompok.value;
          pengguna2.value = idPengguna;
          bank.value = null;
          getBank();
        })
        .catch((err) => {
          isCari.value = false;
          console.log(err.response);
          let showError = err.response
            ? err.response.data.error
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Submit Form`,
            variant: "danger",
            solid: true,
          });
        });
    };

    const submit = (isSubmit) => {
      if (isSubmit) {
        isValidasiSubmit.value = true;
        setTimeout(() => {
          submitForm();
        }, 100);
      } else {
        isValidasiSubmit.value = false;
        setTimeout(() => {
          submitFormCari();
        }, 50);
      }
    };

    const changeKelompok = () => {
      if (kelompok.value == "RS / Klinik") {
        $http({
          url: "/mst/rsKlinik/get-data",
          params: {
            row: -1,
          },
          headers: {
            Authorization: "Bearer " + g.$cookies.get("token"),
          },
        })
          .then((res) => {
            dataPengguna.value = res.data.data;
            dataPengguna.value.unshift({
              text: "-- Pilih Opsi --",
              value: null,
            });
            labelPengguna.value = "RS / Klinik";
          })
          .catch((err) => {
            let showError = err.response
              ? err.response.data
              : err.request
              ? err.request
              : "Error" + err.message;

            g.$bvToast.toast(showError || "Something Wrong", {
              title: `Error Submit Form`,
              variant: "danger",
              solid: true,
            });
          });
      } else if (kelompok.value == "Dokter Pemeriksa") {
        $http({
          url: "/mjmn/pengguna/get-dokter/dokter-pemeriksa",
          headers: {
            Authorization: "Bearer " + g.$cookies.get("token"),
          },
        })
          .then((res) => {
            dataPengguna.value = res.data.data.dataDokterVal;
            labelPengguna.value = "Dokter Pemeriksa";
          })
          .catch((err) => {
            let showError = err.response
              ? err.response.data
              : err.request
              ? err.request
              : "Error" + err.message;

            g.$bvToast.toast(showError || "Something Wrong", {
              title: `Error Submit Form`,
              variant: "danger",
              solid: true,
            });
          });
      } else if (kelompok.value == "Dokter Pengirim") {
        $http({
          url: "/mjmn/pengguna/get-dokter/dokter-pengirim",
          headers: {
            Authorization: "Bearer " + g.$cookies.get("token"),
          },
        })
          .then((res) => {
            dataPengguna.value = res.data.data.dataDokterVal;
            labelPengguna.value = "Dokter Pengirim";
          })
          .catch((err) => {
            let showError = err.response
              ? err.response.data
              : err.request
              ? err.request
              : "Error" + err.message;

            g.$bvToast.toast(showError || "Something Wrong", {
              title: `Error Submit Form`,
              variant: "danger",
              solid: true,
            });
          });
      } else if (kelompok.value == "Langsung") {
        noSediaan.value = { text: "-- Pilih Opsi --", value: null };
        getPesanan(null);
      }

      pengguna.value = { text: "-- Pilih Opsi --", value: null };
    };

    const searchNoSediaan = (search) => {
      getPesanan(search);
    };

    const getPesanan = (key) => {
      $http({
        url: "/pemeriksaan/pemeriksaan/get-list-pesanan",
        params: { key },
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          dataNoSediaan.value = res.data.data;
          dataNoSediaan.value.unshift({
            text: "-- Pilih Opsi --",
            value: null,
          });
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Submit Form`,
            variant: "danger",
            solid: true,
          });
        });
    };

    const selectionChangedTable = (data) => {
      dataSelectedRows.value = data.selectedRows;
    };

    const changeTambahanBiaya = () => {
      tambahanPersen.value = convertIntToPersen(
        tambahanBiaya.value,
        subtotal.value
      );
    };

    const keyupTambahanBiaya = () => {
      tambahanBiaya.value = intThousand(tambahanBiaya.value);
    };

    const changeTambahanPersen = () => {
      tambahanBiaya.value = convertPersenToInt(
        tambahanPersen.value,
        subtotal.value
      );
    };

    const keyupTambahanPersen = () => {
      tambahanPersen.value = intThousand(tambahanPersen.value);
    };

    const changePotonganHarga = () => {
      potonganPersen.value = convertIntToPersen(
        potonganHarga.value,
        subtotal.value
      );
    };

    const keyupPotonganHarga = () => {
      potonganHarga.value = intThousand(potonganHarga.value);
    };

    const changePotonganPersen = () => {
      potonganHarga.value = convertPersenToInt(
        potonganPersen.value,
        subtotal.value
      );
    };

    const keyupPotonganPersen = () => {
      potonganPersen.value = intThousand(potonganPersen.value);
    };

    const changePajak = () => {
      pajakPersen.value = convertIntToPersen(pajak.value, subtotal.value);
    };

    const keyupPajak = () => {
      pajak.value = intThousand(pajak.value);
    };

    const changePajakPersen = () => {
      pajak.value = convertPersenToInt(pajakPersen.value, subtotal.value);
    };

    const keyupPajakPersen = () => {
      pajakPersen.value = intThousand(pajakPersen.value);
    };

    var subtotal = computed(() => {
      let sub = 0;
      for (let i = 0; i < dataSelectedRows.value.length; i++) {
        let { biaya } = dataSelectedRows.value[i];
        if (biaya != "" && typeof biaya !== "undefined" && biaya != null) {
          biaya = biaya.toString().replace(/\./g, "");
          sub += parseInt(biaya);
        }
      }
      sub = sub.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return sub;
    });

    var total = computed(() => {
      let tot = 0;
      let subtotalTemp =
        subtotal.value != "" &&
        typeof subtotal.value !== "undefined" &&
        subtotal.value != null
          ? subtotal.value.toString().replace(/\./g, "")
          : 0;
      let tambahanBiayaTemp =
        tambahanBiaya.value != "" &&
        typeof tambahanBiaya.value !== "undefined" &&
        tambahanBiaya.value != null
          ? tambahanBiaya.value.toString().replace(/\./g, "")
          : 0;
      let potonganHargaTemp =
        potonganHarga.value != "" &&
        typeof potonganHarga.value !== "undefined" &&
        potonganHarga.value != null
          ? potonganHarga.value.toString().replace(/\./g, "")
          : 0;
      let pajakTemp =
        pajak.value != "" &&
        typeof pajak.value !== "undefined" &&
        pajak.value != null
          ? pajak.value.toString().replace(/\./g, "")
          : 0;

      tot =
        parseInt(subtotalTemp) +
        parseInt(tambahanBiayaTemp) -
        parseInt(potonganHargaTemp) +
        parseInt(pajakTemp);
      tot = tot.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return tot;
    });

    const changeBiaya = (index, val) => {
      dataListTagihan.value[index].biaya = intThousand(val);
    };

    const changeIntOnly = (evt) => {
      setInputIntOnly(evt);
    };

    const validateState = (item) => {
      const { $dirty, $error } = item;
      return !$dirty ? null : $error ? false : null;
    };

    return {
      v$,
      $externalResults,
      isCari,
      isLoading,
      isSubmit,
      kelompok,
      pengguna,
      labelPengguna,
      tanggal,
      noSediaan,
      tambahanBiaya,
      tambahanPersen,
      potonganHarga,
      potonganPersen,
      pajak,
      pajakPersen,
      subtotal,
      total,
      bank,
      adminKeuangan,
      dataPengguna,
      dataKelompok,
      dataListTagihan,
      dataNoSediaan,
      dataSelectedRows,
      dataBank,
      dataAdminKeuangan,
      validateState,
      submit,
      changeKelompok,
      searchNoSediaan,
      selectionChangedTable,
      changeBiaya,
      keyupTambahanBiaya,
      changeIntOnly,
      changeTambahanBiaya,
      changeTambahanPersen,
      keyupTambahanPersen,
      changePotonganHarga,
      keyupPotonganHarga,
      changePotonganPersen,
      keyupPotonganPersen,
      changePajak,
      keyupPajak,
      changePajakPersen,
      keyupPajakPersen,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    flatPickr,
    VueGoodTable,
    BCard,
    BForm,
    BFormRow,
    BCol,
    BFormInput,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BCardTitle,
    BSpinner,
    BFormSelect,
    BRow,
  },
  data() {
    return {
      columns: [
        {
          label: "No",
          field: "no",
          width: "1%",
        },
        {
          label: "No Sediaan",
          field: "noSediaan",
        },
        {
          label: "Nama Pasien",
          field: "nama",
        },
        {
          label: "Dokter Pengirim",
          field: "dokterPengirim",
        },
        {
          label: "Rekam Medis",
          field: "noRekamMedis",
        },
        {
          label: "RS / Klinis",
          field: "rsKlinik",
        },
        {
          label: "Tanggal Diterima",
          field: "tanggalTerima",
        },
        {
          label: "Sediaan",
          field: "sediaan",
        },
        {
          label: "Biaya",
          field: "biaya",
          width: "150px",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scope>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>