import helper from '@/libs/helper'
const url = '/mst/tagihan/bank/'
import GeneralApi from "@/composables/GeneralApi";

class Bank extends GeneralApi {
  constructor(g) {
    super(g, url);
    this.url = url;
  }

  getOpsiBank(id = '') {
    return this.g.$http({
      url: `${this.url}get-opsi/${id}`,
      headers: { Authorization: `Bearer ${this.g.$cookies.get('token')}` }
    })
      .then(res => ({
        isSuccess: true,
        data: res.data
      }))
      .catch(err => {
        helper.showError(this.g, err, `Error Get Data`)

        return { isSuccess: false }
      })
  }
}

export default Bank