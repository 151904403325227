module.exports = {
    convertPersenToInt: (value, subtotal) => {
        let hasil = ''
        if (value != '' && typeof value !== 'undefined') {
            let valueTemp = value.toString().replace(/\./g, '')
            let subtotalTemp = subtotal.toString().replace(/\./g, '')
            let hasilTemp = parseInt(valueTemp) * parseInt(subtotalTemp) / 100
            hasilTemp = hasilTemp.toFixed(2).toString().replace('.', ',')
            hasil = hasilTemp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }

        return hasil
    },
    convertIntToPersen: (value, subtotal) => {
        let hasil = ''
        if (value != '' && typeof value !== 'undefined') {
            let valueTemp = value.toString().replace(/\./g, '')
            let subtotalTemp = subtotal.toString().replace(/\./g, '')
            let hasilTemp = parseInt(valueTemp) / parseInt(subtotalTemp) * 100
            hasil = hasilTemp.toFixed(2).toString().replace('.', ',')
        }

        return hasil
    },
    intThousand: value => {
        let hasil = value

        if (hasil != '' && typeof hasil !== 'undefined') {
            hasil = hasil.toString().replace(/\./g, '')
            hasil = hasil.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }

        return hasil
    },
    setInputIntOnly: (evt) => {
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            evt.preventDefault()
    },
    showError: (g, err, title) => {
        let showError = ''
        if (err.toString() == 'Error: Network Error')
            showError = 'Periksa kembali jaringan anda'
        else
            showError = err.response ? (err.response.data.error ? err.response.data.error : err.response.data) : err.request ? err.request : 'Error' + err.message;

        g.$bvToast.toast(showError || 'Something Wrong', {
            title,
            variant: 'danger',
            solid: true,
        })
    },
    getDataUri(url) {
        return new Promise(resolve => {
            var image = new Image();
            image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

            image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth;
                canvas.height = this.naturalHeight;

                //next three lines for white background in case png has a transparent background
                var ctx = canvas.getContext('2d');
                ctx.fillStyle = '#fff';  /// set white fill style
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                canvas.getContext('2d').drawImage(this, 0, 0);

                resolve(canvas.toDataURL('image/png'));
            };

            image.src = url;
        })
    }
};