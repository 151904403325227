<template>
  <Form
    :action="action"
    :dataListTagihanVal="[]"
    :adminKeuanganVal="adminKeuangan"
    :dataAdminKeuanganVal="dataAdminKeuangan"
  />
</template>

<script>
import Form from "./Form.vue";
import { ref } from "@vue/composition-api";
import ApiPengguna from "@/composables/mjmn/pengguna";

export default {
  setup(props, context) {
    const g = context.root;
    const apiPengguna = new ApiPengguna(g);

    let adminKeuangan = ref(null);
    let dataAdminKeuangan = ref([]);

    const getPenggunaKeuangan = async (id = "") => {
      const response = await apiPengguna.getPenggunaKeuangan(id);
      dataAdminKeuangan.value = [
        { text: "-- Pilih Opsi --", value: null },
        ...response.data.data.dataPengguna,
      ];
      adminKeuangan.value = response.data.data.pengguna;
    };

    getPenggunaKeuangan();

    return { adminKeuangan, dataAdminKeuangan };
  },
  data() {
    return {
      action: "Tambah",
    };
  },
  components: {
    Form,
  },
};
</script>