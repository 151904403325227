import { showError } from "@/libs/helper";

export default class GeneralApi {
    constructor(g, url) {
        this.url = url;
        this.g = g;
    }

    dataTable(params) {
        return this.g.$http({
            url: this.url + 'get-data',
            params,
            headers: { Authorization: `Bearer ${this.g.$cookies.get('token')}` }
        })
            .then(res => ({
                isSuccess: true,
                data: res.data
            }))
            .catch(err => {
                showError(this.g, err, `Error Get Data`)

                return { isSuccess: false }
            })
    }

    submit(data, action) {
        return this.g.$http({
            url: this.url + 'submit/' + action,
            data,
            method: 'post',
            headers: { Authorization: `Bearer ${this.g.$cookies.get('token')}` }
        })
            .then(res => ({
                isSuccess: true
            }))
            .catch(err => {
                showError(this.g, err, `Error Submit Data`)

                return { isSuccess: false }
            })
    }

    detail(id) {
        return this.g.$http({
            url: this.url + 'detail/' + id,
            headers: { Authorization: `Bearer ${this.g.$cookies.get('token')}` }
        })
            .then(res => ({
                isSuccess: true,
                data: res.data
            }))
            .catch(err => {
                showError(this.g, err, `Error Get Data`)

                return { isSuccess: false }
            })
    }

    delete(id) {
        return this.g.$http({
            url: this.url + id,
            method: 'delete',
            headers: { Authorization: `Bearer ${this.g.$cookies.get('token')}` }
        })
            .then(res => ({
                isSuccess: true
            }))
            .catch(err => {
                showError(this.g, err, `Error Delete Data`)

                return { isSuccess: false }
            })
    }
}